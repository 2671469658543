import React, { useRef } from "react";
import OfferteWidget from "./OfferteWidget";

import banner from "../img/solar-banner.jpg";

const HomepageBanner = ({ image = banner, imgAlt = "", title, subtitle, offerteWidget, offerteType }) => {
    const bannerRef = useRef(null);

    const scrollDown = () => {
        if (!bannerRef.current) {
            return;
        }
        const bannerHeight = bannerRef.current.offsetHeight;
        window.scrollTo({
            top: bannerHeight - 80,
            behavior: "smooth"
        });
    };
    return (
        <div className="home-banner" ref={bannerRef}>
            <img src={image} alt={imgAlt} />
            <div className="overlay">
                <div className="content-container">
                    <div className="title-container">
                        <h1 className="title"> {title}</h1>
                        <p className="subtitle">{subtitle}</p>
                    </div>
                    {offerteWidget && (
                        <div className="offerte-widget">
                            <OfferteWidget predefinedType={offerteType} />
                        </div>
                    )}
                </div>
            </div>
            <div className="scroll-down-indicator" onClick={scrollDown}>
                <i className="fas fa-chevron-down fa-2xl"/>
            </div>
        </div>
    );
};

export default React.memo(HomepageBanner);
